:root{
  margin: 0%;
  padding: 0%;
}

.editor::-webkit-scrollbar{
  display: none;
}

.resume::-webkit-scrollbar{
  display: none;
}

.resume,.modal
{
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.modal::-webkit-scrollbar{
  display: none;
}
.flex-container{
  display: flex;
  flex-wrap: wrap;
}
.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}
ul,ol{
  padding: 0 0 0 2rem;

}
.text-editor a{
color: rgb(65, 146, 212); 
text-decoration: underline
} 
.editor-view{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  word-break: break-word;
}

.text-editor h1{
  display: block;
font-size: 2em;
margin-left: 0;
margin-right: 0;
}

.text-editor h2{
	display: block;
font-size: 1.5em;
margin-left: 0;
margin-right: 0;
}

.text-editor h3{
  display: block;
  font-size: 1.17em;
  margin-left: 0;
  margin-right: 0;
}

.text-editor h4{
  display: block;
  font-size: 1em;
  margin-left: 0;
  margin-right: 0;
}

.text-editor h5{
  display: block;
  font-size: .83em;
  margin-left: 0;
  margin-right: 0;
}

.text-editor h6{
  display: block;
  font-size: .67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
}

.text-color-teal{
  color: rgb(15 118 110);
}

.bg-color-teal{
  background-color: rgb(15 118 110);
}

.border-color-teal{
  border-color: #14b8a6;
}

.text-color-indigo{
  color: rgb(67 56 202);
}

.bg-color-indigo{
  background-color: #6366f1;
}

.border-color-indigo{
  border-color: #6366f1;
}

.text-color-blue{
  color: rgb(29, 155, 240);
}

.bg-color-blue{
  background-color: rgb(29, 155, 240);
}

.border-color-blue{
  border-color: rgb(29, 155, 240);
}

.note a {
  color: #58a6ff;
  text-decoration: underline;
}

.note{
  z-index: 9999;
}

.todo-details h1{
  font-weight: bold;
  font-size: 1.125rem;
}

.note p{
  font-size: 0.75rem;
}

.line-wrap{
    
  overflow-wrap: break-word;
  white-space: pre-wrap;
} 