@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

@layer base {
    ul, ol {
      list-style: revert;
    }
  }

  @layer components{
    body{
      @apply leading-relaxed font-open bg-slate-100;
  }
  .margin-border{
    @apply border-t-2 border-b-2 border-slate-900;
  }
  .tooltip {
    @apply absolute w-10 p-2 m-2 min-w-max -left-8 rounded-md shadow-md
    text-white bg-gray-900 
    text-xs font-bold 
    transition-all duration-100 scale-0 origin-left;
  }
  .preview-button{
    @apply fixed z-10 flex justify-center items-center bottom-8 right-2 lg:w-[50%] w-[100%] h-6;
  }                                                                                                                                                                                                                             
  .print-button{
    @apply fixed z-10 flex justify-center items-center bottom-8 right-2 w-[100%] h-6;
  }
  .modal-body{
    @apply fixed flex flex-col h-screen z-10 top-0 left-0  ml-2 p-4 shadow-2xl bg-white lg:w-1/2 w-full rounded-lg;
  }
  }                                                                                                             