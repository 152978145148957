@media print
{

.print-pdf{
    width: 100vw;
    height: 100%;
    padding: 0mm;
    line-height: normal;
    -webkit-print-color-adjust: exact; 
    print-color-adjust: exact;
}

.text-editor p,ul{
  font-size: 12px;
}

li,p,ul,a{
  break-inside: avoid;
}

p{
  font-size: 12px;
}
z
.text-editor p,li{
  font-size: 12px;
}


@page {
    margin: 1rem 0 1rem 0;
  }
  
}

.text-editor p,li{
  font-size: 12px;
}

.print-pdf p{
  font-size: 12px;
}
